import { set as _set, merge as _merge } from 'lodash-es';
import General from './General';
import { textAlign, _selector } from './helpers';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;

  const selector = `${_selector(uid, type)} .om-dtr-content`;
  const omFullClass = `${_selector(uid, type)}_wrapper.om-full`;

  _set(_desktop, `["${_selector(uid, type)}"].max-width`, { v: '100%' });
  _set(_mobile, `["${_selector(uid, type)}"].max-width`, { v: '100%' });

  if (element.mobile.textAlign) {
    _merge(_mobile, textAlign(uid, type, element.mobile.textAlign, true, template, selector));

    _set(_mobile, `["${omFullClass}"].justify-content`, { v: element.mobile.textAlign, i: true });
    _set(_mobile, `["${omFullClass}"].display`, { v: 'flex', i: true });
    _mobile[selector]['text-align'].i = true;
  } else {
    _set(_mobile, `["${selector}"].text-align`, { v: 'center', i: true });
  }

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
